import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Temiz Hizmet
			</title>
			<meta name={"description"} content={"Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. "} />
			<meta property={"og:title"} content={"Temiz Hizmet"} />
			<meta property={"og:description"} content={"Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08:50:53.474Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Eviniz, önceliğimiz
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Yoğun programınızın ortasında evinizi temiz ve düzenli tutmanın göz korkutucu bir görev olabileceğinin farkındayız. Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. Temiz Hizmet olarak temiz bir evin mutlu bir yuva olduğuna inanıyoruz ve yaşam alanınızı ışıltılı hale getirmek için olağanüstü temizlik çözümleri sunmaya kendimizi adadık.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							Hizmetlerimiz &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Deneyimli profesyoneller
			</Text>
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" width="50%" lg-width="100%">
					<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						Ekibimiz, işleriyle gurur duyan ve evinizin tertemiz olmasını sağlayan yüksek vasıflı ve deneyimli temizlikçilerden oluşmaktadır.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					margin="0px 0px 0px 10%"
					width="40%"
					display="flex"
					justify-content="space-around"
					lg-margin="50px 0px 0px 0"
					lg-width="100%"
					lg-justify-content="flex-start"
					sm-flex-direction="column"
					sm-align-items="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						sm-margin="0px 0 30px 0px"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 600 72px/1.2 --fontFamily-serifGeorgia"
							color="#cd9f55"
							border-color="#dca654"
							sm-text-align="left"
						>
							60+
						</Text>
						<Text margin="15px 0px 0px 0px" color="#050c32" font="400 20px --fontFamily-sansTrebuchet">
							daimi ortaklar
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" text-align="center">
						<Text
							margin="0px 0px 0px 0px"
							font="normal 600 72px/1.2 --fontFamily-serifGeorgia"
							color="#cd9f55"
							border-color="#dca654"
							sm-text-align="left"
						>
							400+
						</Text>
						<Text margin="15px 0px 0px 0px" color="#050c32" font="400 20px --fontFamily-sansTrebuchet">
							Memnun müşteriler
						</Text>
					</Box>
				</Box>
			</Box>
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Çevre dostu ürünler
			</Text>
			<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
				Sadece çevre dostu ve toksik olmayan temizlik ürünleri kullanarak sağlığınızı ve çevreyi ön planda tutuyoruz.
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Bireysel temizlik planları
			</Text>
			<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
				Hizmetlerimizi özel ihtiyaçlarınıza ve tercihlerinize göre kişiselleştirerek evinizin her bölümünün tam istediğiniz gibi temizlenmesini sağlayın.
			</Text>
		</Section>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08:50:53.440Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						order="1"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Temiz Hizmet Hizmetlerini seçmek kaliteyi, güvenilirliği ve gönül rahatlığını seçmek anlamına gelir. evinize hak ettiği önem verilecektir.
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Temiz Hizmet Services olarak misyonumuz siz ve aileniz için tertemiz ve konforlu bir ortam sağlamaktır. Temizlik sektöründe uzun yıllara dayanan deneyimimizle becerilerimizi geliştirdik ve büyük veya küçük her türlü pisliğin üstesinden gelmek için etkili temizlik yöntemleri geliştirdik. Profesyonel temizlikçilerden oluşan ekibimiz kapsamlı bir şekilde eğitilmiş, incelenmiş ve mükemmelliğe kendini adamıştır. İşimizle gurur duyuyoruz ve her temizlikte beklentilerinizi aşmaya çalışıyoruz.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							İletişim &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-3" font="--headline2">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Hizmetlere genel bakış
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--lead"
				color="--dark"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Eviniz ve aileniz için güvenli, çevre dostu temizlik ürünleri kullanıyoruz. Esnek planlama seçeneklerimiz, ister tek seferlik kapsamlı bir temizliğe ister düzenli bakıma ihtiyacınız olsun, hizmetlerimizi size uygun bir zamanda ayırtmanıza olanak tanır. Yaşam tarzınıza ve tercihlerinize uyacak şekilde özelleştirilmiş temizlik planları sunarak evinizin her köşesine hak ettiği özenin gösterilmesini sağlıyoruz.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08:50:53.458Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="center">
						Ev temizliği
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08:50:53.477Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="center">
						Varışta/ayrılışta temizlik
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08:50:53.448Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="center">
						Özel temizlik
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-red"
				font="normal 400 17px/1.5 --fontFamily-sans"
				href="/services"
				type="link"
				text-decoration-line="initial"
			>
				Tüm hizmetler
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text2" />
			<Override slot="text1" />
			<Override slot="link3" />
			<Override slot="text3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});